@import '../../style/constantes.less';

.applications-map-search {
  .ant-btn {
    font-family: 'PT Serif';
    font-size: 14px;
    height: 32px;
    width: 130px;
  }
  .ant-form-item-control {
    text-align: left;
  }
}

@primary-color: #1DA57A;